<template>
  <el-dialog
    top="80px"
    width="600px"
    class="update-user-popup"
    title="Cập nhật tài khoản"
    :visible="visible"
    :close-on-click-modal="false"
    @opened="loadData"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item prop="imageUrl">
        <el-upload
          v-loading="loading"
          class="avatar-uploader"
          list-type="image"
          accept="image/*"
          action
          :show-file-list="false"
          :http-request="uploadAudios"
        >
          <el-avatar :size="100" :src="form.imageUrl" class="avatar"></el-avatar>
          <div class="hover-upload">
            <i class="el-icon-camera-solid"></i>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item v-if="user.user_type === 'staff'" label="Loại tài khoản" prop="staffType">
        <el-select v-model="form.staffType" class="w-100">
          <el-option
            v-for="(t, index) in types.filter((t) => t.value !== 'user')"
            :key="index"
            :label="t.label"
            :value="t.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-row v-if="form.staffType && form.staffType !== 'user'" :gutter="12">
        <el-col :span="12">
          <el-form-item label="Phòng ban" prop="departmentId">
            <el-select v-model="form.departmentId" class="w-100">
              <el-option
                v-for="(t, index) in allDepartment"
                :key="index"
                :label="t.name"
                :value="t.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Nơi làm việc" prop="workPlaceId">
            <el-select v-model="form.workPlaceId" class="w-100">
              <el-option
                v-for="(t, index) in allWorkingLocations"
                :key="index"
                :label="t.name"
                :value="t.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="12">
        <el-col :span="12">
          <el-form-item label="Địa chỉ email" prop="email">
            <el-input v-model="form.email" :disabled="callingAPI" placeholder="Nhập email"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Tên" prop="name">
            <el-input v-model="form.name" :disabled="callingAPI" placeholder="Nhập tên"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="12">
        <el-col :span="12">
          <el-form-item label="Số điện thoại" prop="phone">
            <el-input v-model="form.phone" :disabled="callingAPI" placeholder="Nhập số điện thoại"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Mật khẩu" prop="password">
            <el-input v-model="form.password" :disabled="callingAPI" placeholder="Nhập mật khẩu"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleUpdateUser">Cập nhật</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { createStorage } from '@/services/storage'
import { updateUser } from '@/services/user'
import { getUserRoles } from '@/utils/filters'

export default {
  name: 'UpdateUser',
  props: {
    visible: Boolean,
    user: Object,
    allDepartment: Array,
    allWorkingLocations: Array
  },
  data() {
    return {
      callingAPI: false,
      loading: false,
      types: getUserRoles(),
      form: {
        imageUrl: '',
        email: '',
        name: '',
        phone: '',
        staffType: '',
        password: '',
        departmentId: '',
        workPlaceId: ''
      },
      rules: {
        imageUrl: [
          { required: true, message: 'Avartar không được để trống', trigger: ['blur', 'change'] }
        ],
        email: [
          { required: true, message: 'Địa chỉ email không được để trống', trigger: ['blur', 'change'] },
          { type: 'email', message: 'Địa chỉ email không hợp lệ', trigger: ['blur', 'change'] }
        ],
        name: [
          { required: true, message: 'Tên không được để trống', trigger: ['blur', 'change'] }
        ],
        phone: [
          { required: true, message: 'Số điện thoại không được để trống', trigger: ['blur', 'change'] }
        ],
        departmentId: [
          { required: true, message: 'Phòng ban không được để trống', trigger: ['blur', 'change'] }
        ],
        workPlaceId: [
          { required: true, message: 'Nơi làm việc không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  ...mapGetters(['language']),
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.form.imageUrl = this.user.avatar_url
      this.form.email = this.user.email
      this.form.name = this.user.name
      this.form.phone = this.user.phone
      this.form.staffType = this.user.staff_type || ''
      this.form.departmentId = this.user.department_id
      this.form.workPlaceId = this.user.work_place_id
    },

    uploadAudios(fileInfo) {
      const params = {
        file: fileInfo.file,
        objectId: this.user.id,
        objectType: 'user',
        fileType: 'image',
        locale: this.language
      }
      this.loading = true
      createStorage(params).then((res) => {
        this.form.imageUrl = res.data.url
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.$notify.error({
          title: 'Thông báo',
          message: 'Không thể upload avatar: ' + fileInfo.file.name
        })
      })
    },
    handleUpdateUser() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          var slugify = require('slugify')
          this.callingAPI = true
          let data = {
            created_at: this.user.created_at,
            avatar_url: this.form.imageUrl,
            deleted: false,
            email: this.form.email,
            id: this.user.id,
            name: this.form.name,
            phone: this.form.phone,
            status: this.user.status,
            slug: slugify(this.form.name, '-'),
            updated_at: dayjs().format('DD/MM/YYYY'),
            user_type: this.user.user_type,
            staff_type: this.form.staffType,
            department_id: this.form.departmentId,
            work_place_id: this.form.workPlaceId
          }
          if (this.form.password.trim()) {
            data = { ...data, password: this.form.password }
          }
          updateUser(data).then(() => {
            this.$notify.success({
              title: 'Thành công',
              message: 'Cập nhật thành công'
            })
            this.handleClose()
            this.$emit('reload')
            this.callingAPI = false
          }).catch(() => {
            this.$notify.error({
              title: 'Thất bại',
              message: 'Cập nhật thất bại'
            })
            this.callingAPI = false
          })
        }
      })
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-uploader {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &:hover {
    .hover-upload {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }
  .avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    z-index: 1;
  }
  .hover-upload {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #00000047;
    z-index: 999;
    transition: all 0.5s ease-in-out;
    i {
      color: #333;
      font-size: 50px;
    }
  }
}
</style>
<style lang="scss">
</style>
